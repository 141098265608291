import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { colStyles } from './mixins';

const fullFlexBasis = 'calc(100% * 1/3)';
const FooterSummary = styled('div')(({ theme }) => ({
  ...colStyles,
  flexBasis: fullFlexBasis,
  maxWidth: fullFlexBasis,

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    flexBasis: '100%',
    maxWidth: '100%',
  },

  ul: {
    margin: '2rem 0',
    width: '31rem',

    li: {
      fontSize: '2.2rem',
      width: '16.6%',

      [mediaQueryBuilder(theme.mediaQueries.min.width['768'])]: {
        fontSize: '1.6rem',
        width: '10.6%',
      },
    },
  },
}));

export default FooterSummary;
