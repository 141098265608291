import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { colStyles } from './mixins';

const fullFlexBasis = 'calc(100% * 2/3)';
const FooterNav = styled('div')(({ theme }) => ({
  ...colStyles,
  flexBasis: fullFlexBasis,
  maxWidth: fullFlexBasis,

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    flexBasis: '100%',
    maxWidth: '100%',
  },

  ul: {
    li: {
      float: 'left',
      listStyle: 'none',
    },
  },
}));

export default FooterNav;
