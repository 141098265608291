import NavLink from 'components/NavLink';

type Props = {
  dataTest?: string;
  localeTarget?: string | null;
  socialKey: string;
  target: string;
  title: string;
  Icon?: any;
};

function FooterSocialLink({
  socialKey,
  target,
  title,
  localeTarget,
  Icon,
  dataTest,
}: Props) {
  if (!localeTarget) return null;

  return (
    <li key={socialKey}>
      <NavLink
        dataTest={dataTest}
        itemProp="sameAs"
        target="_blank"
        title={title}
        to={target + localeTarget}
      >
        {Icon}
      </NavLink>
    </li>
  );
}

export default FooterSocialLink;
