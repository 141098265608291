import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

export const SubHeader = styled('div')(({ theme }) => ({
  margin: '0 1rem 6rem 0',
  maxWidth: '23rem',

  [mediaQueryBuilder(theme.mediaQueries.min.width['768'])]: {
    margin: '0 7rem 5rem 0',
  },
}));

export default SubHeader;
