import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { colStyles } from './mixins';

const FooterLinkList = styled('div')(({ theme }) => ({
  ...colStyles,
  flexBasis: '25%',
  maxWidth: '25%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    flexBasis: '50%',
    marginBottom: '2rem',
    maxWidth: '50%',
  },

  h1: {
    marginBottom: '1.2rem',
  },

  p: {
    fontSize: '1.3rem',
    lineHeight: '1.8rem',
    marginBottom: '1.2rem',
  },
}));

export default FooterLinkList;
