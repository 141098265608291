import FooterRow from './FooterRow';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  newFooter: boolean;
};

export const FooterContent = styled(FooterRow)<Props>(
  ({ newFooter = false, theme }) => ({
    borderTop: newFooter ? 'none' : `1px solid ${theme.colors.gray['300']}`,
    marginTop: 0,
    padding: '3.5rem 0',
    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      padding: 0,
    },
  }),
);

export default FooterContent;
