import styled from '@emotion/styled';

const FooterSummary = styled('div')({
  marginBottom: '3rem',

  h3: {
    marginBottom: '1.5rem',
  },

  ul: {
    li: {
      a: {
        textDecoration: 'none',
      },
      display: 'inline-block',
    },
    padding: 0,
  },
});

export default FooterSummary;
