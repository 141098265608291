import Container from './primitives/Container';
import Copyright from 'components/Copyright';
import FacebookCircle from 'styles/icons/FacebookCircle';
import FooterConnect from './primitives/FooterConnect';
import FooterContent from './primitives/FooterContent';
import FooterLink from './FooterLink';
import FooterLinkList from './primitives/FooterLinkList';
import FooterLogo from './primitives/FooterLogo';
import FooterNav from './primitives/FooterNav';
import FooterRow from './primitives/FooterRow';
import FooterSocialLink from './FooterSocialLink';
import FooterSubheader from './primitives/FooterSubheader';
import FooterSummary from './primitives/FooterSummary';
import FooterWrapper from './primitives/FooterWrapper';
import Instagram from 'styles/icons/Instagram';
import SubHeader from './primitives/SubHeader';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import X from 'styles/icons/X';
import YouTube from 'styles/icons/YouTube';

export type Props = {
  adAboveNav?: boolean;
  appLinks: {
    auto: string;
    home: string;
    mobile: string;
    wear: string;
  };
  customRadioEnabled: boolean;
  infoLinks: {
    about: string;
    advertise: string;
    blog: string;
    brand: string;
    help: string;
    jobs: string;
    rules: string;
    subscription: string;
  };
  navLinks: {
    contest: string;
    custom: string;
    events: string;
    feature: string;
    forYou: string;
    genre: string;
    live: string;
    news: string;
    photo: string;
    playlist: string;
    podcast: string;
  };
  showTakeover?: boolean;
  newFooter?: boolean;
  piiRegulationEnabled?: boolean;
  piiDashboardLink?: string;
  privacy: string;
  showPlaylist: boolean;
  socialLinks: {
    facebook: string | undefined;
    instagram: string | undefined;
    twitter: string | undefined;
    youtube: string | undefined;
  };
  terms: string;
};

function Footer({
  showTakeover,
  socialLinks,
  navLinks,
  infoLinks,
  appLinks,
  privacy,
  terms,
  showPlaylist,
  customRadioEnabled,
  piiRegulationEnabled,
  piiDashboardLink,
  newFooter = false,
}: Props) {
  const translate = useTranslate();

  const {
    forYou,
    live,
    custom,
    genre,
    podcast,
    news,
    feature,
    events,
    contest,
    photo,
    playlist,
  } = navLinks;
  const { about, advertise, blog, brand, rules, help, jobs, subscription } =
    infoLinks;
  const { auto, home, mobile, wear } = appLinks;
  const { facebook, twitter, instagram, youtube } = socialLinks;

  return (
    <Container newFooter={newFooter}>
      <FooterWrapper showTakeover={showTakeover}>
        <FooterContent
          data-test="footer-content"
          newFooter={newFooter || false}
        >
          <FooterSummary>
            <div>
              <FooterLogo classes={[]} dark forceDefault />
              <SubHeader data-test="footer-sub-header">
                {translate(
                  'Music, radio and podcasts, all free. Listen online or download the iHeart App.',
                )}
              </SubHeader>
            </div>
            <FooterConnect>
              <FooterSubheader>{translate('Connect')}</FooterSubheader>
              <ul itemScope itemType="http://schema.org/Organization">
                <meta content="iHeart" itemProp="name" />
                <link href="https://www.iheart.com" itemProp="url" />
                <FooterSocialLink
                  Icon={
                    <FacebookCircle
                      css={{ marginBottom: '-.2rem' }}
                      height="20"
                    />
                  }
                  localeTarget={facebook}
                  socialKey="facebook"
                  target="//www.facebook.com/"
                  title={translate('Connect with Facebook')}
                />
                <FooterSocialLink
                  Icon={<X height="16" width="16" />}
                  localeTarget={twitter}
                  socialKey="twitter"
                  target="//x.com/"
                  title={translate('Connect with X')}
                />
                <FooterSocialLink
                  Icon={<Instagram />}
                  localeTarget={instagram}
                  socialKey="instagram"
                  target="//instagram.com/"
                  title={translate('iHeart Instagram')}
                />
                <FooterSocialLink
                  Icon={<YouTube height="17" />}
                  localeTarget={youtube}
                  socialKey="youtube"
                  target="//www.youtube.com/user/"
                  title={translate('iHeart Youtube')}
                />
              </ul>
            </FooterConnect>
          </FooterSummary>
          <FooterNav>
            <FooterRow data-test="footer-row">
              <FooterLinkList>
                <FooterSubheader>{translate('Explore')}</FooterSubheader>
                <FooterLink
                  label={translate('iHeart')}
                  linkKey="home"
                  localeTarget={forYou}
                  title={translate('iHeart')}
                />
                <FooterLink
                  label={translate('Live Radio')}
                  linkKey="liveradio"
                  localeTarget={live}
                  title={translate('Live Radio')}
                />
                <FooterLink
                  label={translate('Podcasts')}
                  linkKey="podcasts"
                  localeTarget={podcast}
                  title={translate('On Demand Podcasts')}
                />
                {customRadioEnabled ?
                  <FooterLink
                    label={translate('Artist Radio')}
                    linkKey="customradio"
                    localeTarget={custom}
                    title={translate('Artist Radio')}
                  />
                : null}
                {showPlaylist ?
                  <FooterLink
                    label={translate('Playlists')}
                    linkKey="playlists"
                    localeTarget={playlist}
                    title={translate('Playlists')}
                  />
                : null}
                <FooterLink
                  label={translate('Genres')}
                  linkKey="genres"
                  localeTarget={genre}
                  title={translate('Browse by Genre')}
                />
              </FooterLinkList>
              <FooterLinkList>
                <FooterSubheader>{translate('Exclusives')}</FooterSubheader>
                <FooterLink
                  label={translate('News')}
                  linkKey="news"
                  localeTarget={news}
                  target="_blank"
                  title={translate('iHeart News')}
                />
                <FooterLink
                  label={translate('Features')}
                  linkKey="features"
                  localeTarget={feature}
                  target="_blank"
                  title={translate('Featured Exclusives on iHeart')}
                />
                <FooterLink
                  label={translate('Events')}
                  linkKey="events"
                  localeTarget={events}
                  target="_blank"
                  title={translate('iHeart Events')}
                />
                <FooterLink
                  label={translate('Contests')}
                  linkKey="contests"
                  localeTarget={contest}
                  target="_blank"
                  title={translate('iHeart Contests')}
                />
                <FooterLink
                  label={translate('Photos')}
                  linkKey="photos"
                  localeTarget={photo}
                  target="_blank"
                  title={translate('iHeart Photos')}
                />
              </FooterLinkList>
              <FooterLinkList>
                <FooterSubheader>{translate('Information')}</FooterSubheader>
                <FooterLink
                  label={translate('About')}
                  linkKey="about"
                  localeTarget={about}
                  target="_blank"
                  title={translate('About iHeart')}
                />
                <FooterLink
                  label={translate('Advertise')}
                  linkKey="advertise"
                  localeTarget={advertise}
                  target="_blank"
                  title={translate('Advertise on iHeart')}
                />
                <FooterLink
                  label={translate('Blog')}
                  linkKey="blog"
                  localeTarget={blog}
                  target="_blank"
                  title={translate('iHeart Blog')}
                />
                <FooterLink
                  label={translate('Brand Guidelines')}
                  linkKey="brand"
                  localeTarget={brand}
                  target="_blank"
                  title={translate('iHeart Logo and Guidelines')}
                />
                <FooterLink
                  label={translate('Contest Guidelines')}
                  linkKey="contestrules"
                  localeTarget={rules}
                  title={translate('iHeart Contest Rules')}
                />
                <FooterLink
                  label={translate('Subscription Offers')}
                  linkKey="subscriptionoffers"
                  localeTarget={subscription}
                  title={translate('Subscription Offers')}
                />
                <FooterLink
                  label={translate('Jobs')}
                  linkKey="jobs"
                  localeTarget={jobs}
                  target="_blank"
                  title={translate('iHeart Jobs')}
                />
              </FooterLinkList>
              <FooterLinkList>
                <FooterSubheader>{translate('Get the App')}</FooterSubheader>
                <FooterLink
                  label={translate('Automotive')}
                  linkKey="auto"
                  localeTarget={auto}
                  target="_blank"
                  title={translate('iHeart in your house')}
                />
                <FooterLink
                  label={translate('Home')}
                  linkKey="home"
                  localeTarget={home}
                  target="_blank"
                  title={translate('iHeart in your car')}
                />
                <FooterLink
                  label={translate('Mobile')}
                  linkKey="mobile"
                  localeTarget={mobile}
                  target="_blank"
                  title={translate('iHeart on your phone or tablet')}
                />
                <FooterLink
                  label={translate('Wearables')}
                  linkKey="wear"
                  localeTarget={wear}
                  target="_blank"
                  title={translate('iHeart wearables')}
                />
              </FooterLinkList>
            </FooterRow>
          </FooterNav>
        </FooterContent>
        <Copyright
          helpLink={help}
          piiDashboardLink={piiDashboardLink}
          piiRegulationEnabled={piiRegulationEnabled}
          privacyLink={privacy}
          termsLink={terms}
        />
      </FooterWrapper>
    </Container>
  );
}

export default Footer;
