import styled from '@emotion/styled';

export const FooterRow = styled('div')({
  boxSizing: 'border-box',
  display: 'flex',
  flex: '0 1 auto',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginLeft: 'calc(1rem * 0.5 * -1)',
  marginRight: 'calc(1rem * 0.5 * -1)',
});

export default FooterRow;
