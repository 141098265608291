import Logo from 'components/Logo/Logo';
import styled from '@emotion/styled';

const FooterLogo = styled(Logo)({
  display: 'inline-block',
  width: '23rem !important',
  height: '5.4rem',

  svg: {
    display: 'inline-block',
    width: '23rem',
    height: '5.4rem',
  },
});

export default FooterLogo;
