import styled from '@emotion/styled';

const FooterSubheader = styled('h2')(({ theme }) => ({
  color: theme.colors.red.primary,
  fontSize: theme.fonts.size.xsmall,
  fontWeight: 'normal',
  letterSpacing: '.2rem',
  lineHeight: `calc(1.35 * ${theme.fonts.size.xsmall})`,
  marginBottom: '1.2rem',
  textTransform: 'uppercase',
}));

export default FooterSubheader;
