import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  fill?: string;
  height?: string;
  width?: string;
};

function FacebookCircle({
  'data-test': dataTest,
  height = '18',
  width = '18',
  fill = theme.colors.black.secondary,
  ...props
}: Props) {
  return (
    <svg
      data-test={dataTest}
      fill={fill}
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.493 3.29063 17.216 7.59375 17.891V11.6016H5.30859V9H7.59375V7.01719C7.59375 4.76191 8.93672 3.51562 10.9934 3.51562C11.9777 3.51562 13.0078 3.69141 13.0078 3.69141V5.90625H11.8723C10.7543 5.90625 10.4062 6.60058 10.4062 7.3125V9H12.9023L12.5033 11.6016H10.4062V17.891C14.7094 17.216 18 13.493 18 9Z" />
    </svg>
  );
}

export default FacebookCircle;
